<template>
  <div class="article-box">
    <el-table :data="agencyAdmin" style="width: 100%" v-loading="loading">
      <el-table-column prop="serial" label="序号" type="index" align="center" width="100">
        <template slot-scope="scope">
          {{ (currpage - 1) * pagesize + scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column prop="userName" label="用户昵称" align="center"></el-table-column>
      <el-table-column label="问题类型" prop="qtype" align="center"></el-table-column>
      <el-table-column prop="qintro" label="问题描述" align="center"></el-table-column>
      <el-table-column prop="createtime" label="反馈时间" align="center"></el-table-column>
      <el-table-column prop="qcontact" label="联系方式" align="center"></el-table-column>
      <el-table-column label="操作"  align="center">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="detail(scope.row)">详情</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block">
      <el-pagination @current-change="handleCurrentChange" :page-size="pagesize" background
        layout="prev, pager, next, jumper" :total="CountNum" style="margin-top: 20px">
      </el-pagination>
    </div>
	<el-dialog title="问题详情" :visible.sync="dialogVisible" width="40%" center top="5px">
	  <div class="content" v-if="data">
	    <p>
	      <span>问题类型：</span>
	      <span>{{data.qtype}}</span>
	    </p>
	    <p>
	      <span>问题描述：</span>
	      <span>{{data.qintro}}</span>
	    </p>
	    <p>
	      <span>证明材料：</span>
	      <span>
			  <div class="pic-list">
			  <img :src="pic" v-for="pic in data.qpic" :key="pic" @click="openpic(pic)" />
			  </div>
		  </span>
	    </p>
	    <p>
	      <span>联系方式：</span>
	      <span>{{data.qcontact}}</span>
	    </p>
	  </div>
	  <span slot="footer">
	    <el-button @click="dialogVisible = false">返回</el-button>
	  </span>
	</el-dialog>
  </div>
</template>
<script>
  import {
    bindApply,
    bindCheck,
	question
  } from '@/api/adminApi.js'
  export default {
    data() {
      return {
		  dialogVisible:false,
		data: null,
        agencyAdmin: [], // 代理申请学校列表
        loading: false, //加载框
        currpage: 1, // 初始页
        pagesize: 12, // 每页显示条目个数
        CountNum: 0 // 总条数
      }
    },
    mounted() {
      this.applyList(1) // 获取第一页待审核数据
    },
    methods: {
		openpic(pic) {
			window.open(pic)
		},
		detail(row) { // 点击广告详情
		  this.data = row
		  this.dialogVisible = true //打开弹窗
		},
      applyList(currpage) { // 代理投放学校申请列表
        this.loading = true
        const data = {
          page: currpage, // 当前页数
          limit: this.pagesize, // 每页条数
          type: 1 // 	0待审核 1已同意  2已拒绝 10全部
        }
        question(data).then(res => {
          this.agencyAdmin = res.data.list // 待审核列表
          this.CountNum = parseInt(res.data.CountNum) // 总条数
          this.loading = false
        }).catch(err => {
          console.log(err)
        })
      },
      agree(index, row) { // 点击同意
        const data = {
          id: row.ID,
          status: 1
        }
        bindCheck(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '已同意',
              type: 'success'
            })
            this.agencyAdmin.splice(index, 1)
            this.agencyAdmin(this.currpage) // 成功通过后刷新视图
          }
        }).catch(err => {
          console.log(err)
        })
      },
      refuse(index, row) { // 点击拒绝
        const data = {
          id: row.ID,
          status: 2
        }
        bindCheck(data).then(res => {
          if (res.status.code == 1) {
            this.$message({
              message: '已拒绝',
              type: 'success'
            })
            this.agencyAdmin.splice(index, 1)
            this.agencyAdmin(this.currpage) // 成功通过后刷新视图
          }
        }).catch(err => {
          console.log(err)
        })
      },
      Citys(row, column) { // 代理的城市
        return row.ProvinceName + row.CityName
      },
      handleCurrentChange(val) { // 分页
        this.applyList(val)
        this.currpage = val
      }
    }
  }
</script>

<style lang="scss" scoped>
.pic-list {
	display: flex;
	img {
		width: 200px;
		heigth: auto;
		margin-right: 10px;
		cursor: pointer;
	}
}
</style>
